import Vue from 'vue'
import Vuex from 'vuex'

import actions from "./actions"
import mutations from "./mutations"
Vue.use(Vuex)

export default new Vuex.Store({
	// 数据
  state: {
      store_id:"",// 店铺id
      token:"",
      seller_name:"",
      password:"",
      store_name:"",// 店铺名称
      member_id:"",// 员工id 带 - 表示店铺里面的员工
      member_name:"",// 
      nickname:"",
      
      partNumber:"",
      role_id:"",
      limits:"",
      sc_name:"",
      sc_bail:0,
      account_number:"",
      role_name:"",
      position:"",
      // 是否内部采购
      inside:"",
      // 企业简称
      company_name_jc:"",
      // 图片地址
      logo_url:"",
      // 头像地址
      member_avatar:"",
      // 最后登录的时间
      last_login_time:"",
      // 审核id
      flow_id:"",
      group_id:"",
      // 当前正在浏览的商品元素
      itemData:{},
      // 当前正在进行的步骤
      stepIndex:2,
      // 步骤列表
      stepList:["我的购物车","确认订单","付款","支付成功"],
      
      cartNums:0,
      advCart:0,
      searchText:"",
      findGoodsList:[],
      goods_id:"",
      // 注册来源
      register_from:"",
      // 邀请人id
      inviter_id:"",
     
      isBoxScrool:"auto",
      showLoding:false,
      storeText:"",
      storeShopId:"",
      showLoding:false,
      // 是否能够查看电子目录
      canVinSearch:"",
      // epc是否能查看华诺威商品 如果不可查看，直接跳去电子目录
      sc_rent:1,
      // 总次数
      vinTotal:0,
      // 剩余次数
      vinNum:0,
      // 剩余时间 天数
      vin_over_time:0,
      // 剩余积分数量
      integral_num:0,
      brandCode:"",
      sc_id:"",
      // 导航列表
      epcNavList:[],
      // 电子目录的列表上级地址
      epvPreNav:"",
      
      
      // 直营
      for_branch:"",
      transpor_type:"",
      batchs:[],
      // 0库存是否可以购买
      zero_buy:1,
      invDia:false,
      // 缓存店铺信息
      shopInfo:{},

      // 认证状态
      joinin_state:"",
      than:"",
      ratioBrandList:[],
      // 是否是开放平台
      isWebKfpt:false,
      // info:{
      //   shop: "",
        
      //   member_id: "",
      // },
      member_id:"",
      shop:"",
      buyer_id: "",
      shop_type:'',// 店铺的类型
      // 企业的信息
      store_company_name:'',
      contacts_name:'',
      contacts_phone:'',
      area_info:'',
      store_address:'',
      nickname:"",
      is_sh:"",// 判断是上海仓还是丹阳仓,
      is_int_show:0,
      is_limit:1,
      brands:""
   }, 
   getters: {
      notLvD(state){
        // 如果是店铺
        
        if(state.shop&&state.shop!=1780){
          return false
        }else if(state.for_branch){
          // 如果是直营
          return false
        }else{
          return true
        }
      },
      // this.$store.getters.hasLimitBrands
      notLimitBrands(state){
        if(state.brands){
          try {
            if(state.brands.length>0){
              return false
            }
          } catch (error) {
            return true 
          }
        }else{
          return true 
        }
      }
   },
  // 方法
  mutations,
  actions,
})
