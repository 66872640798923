import { post } from "axios"
import store from '../../../store'

// 获取礼品列表商品
const getIntegrallist = async (data)=>{
    return await post("index.php?act=integral&op=list",{
        ...data,
        shop:store.state.for_branch||store.state.shop|| "1780",
        store_id:store.state.store_id
    })
}
// 获取商品分类列表
const getInGoodsClass = async (data)=>{
    return await post("index.php?act=integral&op=classify",{
        ...data,
        shop:store.state.for_branch||store.state.shop|| "1780",
        store_id:store.state.store_id
    })
}
// 签到
const inteSignAdd = async (data)=>{
    return await post("index.php?act=integral&op=signin",{
        member_id:store.state.member_id,
        member_name:store.state.member_name,
        store_id:store.state.store_id,
        store_name:store.state.store_name,
        ...data,
        shop:store.state.for_branch||store.state.shop|| "1780",
        store_id:store.state.store_id
    })
}
// 获取是否已经签到
const getInteSigninMsg = async (data)=>{
    return await post("index.php?act=integral&op=signlist",{
        member_id:store.state.member_id,
        ...data,
        shop:store.state.for_branch||store.state.shop|| "1780",
        store_id:store.state.store_id
    })
}
// 获取明细列表
const getIntegralDelist = async (data)=>{
    return await post("index.php?act=integral&op=record",{
        member_id:store.state.member_id,
        ...data,
        shop:store.state.for_branch||store.state.shop|| "1780",
        store_id:store.state.store_id
    })
}
// 获取兑换记录
const getIntegralHilist = async (data)=>{
    return await post("index.php?act=integral&op=histry",{
        member_id:store.state.member_id,
        ...data,
        shop:store.state.for_branch||store.state.shop|| "1780",
        store_id:store.state.store_id
    })
}
// 获取积分详情
const getIntegralInfo = async (data)=>{
    return await post("index.php?act=integral&op=details",{
        ...data,
        shop:store.state.for_branch||store.state.shop|| "1780",
        store_id:store.state.store_id
    })
}
// 获取推荐
const getIntegralRec = async (data)=>{
    return await post("index.php?act=integral&op=list",{
        ...data,
        shop:store.state.for_branch||store.state.shop|| "1780",
        store_id:store.state.store_id
    })
}
// 生成订单
const createIntegralOrder = async (data)=>{
    return await post("index.php?act=integral&op=order",{
        ...data,
        shop:store.state.for_branch||store.state.shop|| "1780",
        store_id:store.state.store_id
    })
}

export {
    getIntegrallist,
    getInGoodsClass,
    getInteSigninMsg,
    inteSignAdd,
    getIntegralDelist,
    getIntegralHilist,
    getIntegralInfo,
    getIntegralRec,
    createIntegralOrder
}