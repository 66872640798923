<template>
  <div class="store-home">
    <div class="w">
    <div class="head">
      <img src="../../assets/store/banner.png" alt="">
    </div>
    <div class="content">
<!-- $router.push('businesses') @click="goBus(item.store_id)" -->
      <div class="store"   v-for="(item,index) of storeList" :key="index" @click="goBus(item.store_id)">
        <div class="store-img">
          <img :src="item.store_label_url" alt="">
        </div>
        <div class="headline">{{item.shop_name}}</div>
        <!-- <div class="phone">电话: {{item.shop_contactphone}}</div> -->
        <div class="sku">SKU: {{item.sku}}</div>
        <div class="entrance">进入店铺</div>
      </div>
    </div>
    
    </div>
    <el-dialog
			:visible.sync="explVis"
			class="expl-info-dis"
			width="860px"
			top="9vh"
			>
			<div class="img-box">
				<img src="../../assets/index/exploit.png" alt="">
			</div>
	   </el-dialog>
  </div>
  
</template>

<script>
export default {
  data(){
    return{
      storeList:[],
      explVis:false
    }
  },
    async created(){
      let res= await this.$api.getShopList({})
      this.storeList = res
      this.storeList.forEach(item=>{
        item.store_label_url = item.url+ item.store_label
      })
      console.log("店铺首页页获取店铺列表",this.storeList);
    },
    methods:{
        goBus(store_id){
          console.log("我进入了")
          if(!this.$isCompany()){
              return 
          }
          if(this.$store.state.store_id==1780){
            this.$router.push(`businesses/${store_id}`)
            this.$store.commit("UpDateState",{
              storeShopId:store_id
            })
          } 
        }
    },
} 
</script>

<style lang="scss" scoped>
  .store-home{
    background-color: #f7f7f7;
    .w{
   width: 1200px;
   margin: 0 auto;
    .head{
      padding-top: 10px;
      height: 360px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .content{
      margin-top: 10px;
      
      // display: flex;
      // flex-wrap: wrap;
      //   justify-content: space-between;

      .store{
        float: left;
        cursor:pointer;
        width: 290px;
        height: 306px;
        background-color: #ffffff;
        margin:0 13px 10px 0;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        .store-img{
          width: 290px;
          height: 170px;
          // border-bottom: 1px solid #eee;
          >img{
            width: 104px;
            height: 104px;
            margin: 40px 0 0 90px;
          }
        }
        .headline{
          font-size: 18px;
          color: #333;
          text-align: center;
          font-weight: 500;
          // margin: 20px 0 10px 0;
          // padding: 0 10px;
        }
        
        // .phone{
        //   text-align: center;
        //   // padding: 0 70px 0 10px;
        // }
        .sku{
          text-align: center;
          margin: 12px 0 14px 0;
          color: #999;
        }
        .entrance{
          width: 181px;
          height: 39px;
          background: #FFFFFF;
          border: 1px solid #3A82D4;
          border-radius: 2px;
          text-align: center;
          line-height: 39px;
          color: #3A82D4;
          margin-left: 55px;
        }
      }
      .store:nth-child(4n){
        margin-right: 0;
      }
      // .store:hover{
      //   border: 1px solid #3A82D4;
      //   box-sizing: border-box;
      //   .entrance{
      //     background: #3A82D4;
      //     color: #fff;
      //   }
      // }
    }
    }
    .expl-info-dis{
			.img-box{
				margin-left: 120px;
			}
		}
  }
</style>